// src/utils/authHandler.js
import cookie from 'js-cookie';
import client from '../store/apollo'; // Import your Apollo client instance

export const handleAuthFailure = async (dispatch, history) => {
  try {
    // Clear all cookies
    const cookies = cookie.get();
    Object.keys(cookies).forEach(cookieName => {
      cookie.remove(cookieName);
    });

    // Clear localStorage 
    localStorage.clear();
    
    // Reset Apollo client store
    await client.clearStore();
    
    // Reset Redux store - dispatch reset action
    if (dispatch) {
      dispatch({ type: 'CURRENT_USER', payload: 'reset' });
    }

    // Redirect to login
    if (history) {
      history.push('/login');
    }

    // Optional: reload page to ensure clean slate
    window.location.reload();

  } catch (error) {
    console.error('Error during auth cleanup:', error);
    // Force reload as fallback
    window.location.href = '/login';
  }
};

// Helper to check if error is auth error
export const isAuthError = (error) => {
  return error?.message?.includes('You are not authorized to access that resource');
};
