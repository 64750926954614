import React, { Component, createContext } from "react";
import actionCable from "actioncable";
import cookie from "js-cookie";

export const CheckInContext = createContext(null);

class ConnectActionCable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkInMessage: {},
      loadStatus: false,
      cable: null
    };
  }

  componentDidMount() {
    this.initializeConnection();
  }

  initializeConnection = () => {
    const token = cookie.get("token");
    const { parkId } = this.props;

    if (!token || !parkId) {
      console.log('Missing required connection params:', { hasToken: !!token, parkId });
      return;
    }

    try {
      // Initialize cable connection
      const cable = actionCable.createConsumer(
        `${process.env.REACT_APP_API_ENDPOINT}/cable?auth=${token}`
      );

      this.setState({ cable }, () => {
        this.createSocket();
      });
    } catch (err) {
      console.error('Failed to initialize cable connection:', err);
    }
  }

  createSocket = () => {
    const { cable } = this.state;
    const { parkId, setCheckIN } = this.props;

    if (!cable || !parkId) {
      return;
    }

    try {
      this.notify = cable.subscriptions.create(
        {
          channel: "ParkNotifyChannel",
          park_id: parkId
        },
        {
          connected: () => {
            console.log("Cable connection established");
            this.setState({ loadStatus: true });
          },
          disconnected: () => {
            console.log("Cable connection terminated");
            this.setState({ loadStatus: false });
          },
          received: (messages) => {
            console.log("Received message:", messages);
            this.setState({ checkInMessage: messages });
            if (setCheckIN) {
              setCheckIN(messages);
            }
          },
          rejected: () => {
            console.log("Cable connection rejected");
            this.setState({ loadStatus: false });
          }
        }
      );
    } catch (err) {
      console.error('Failed to create subscription:', err);
    }
  }

  componentWillUnmount() {
    const { cable } = this.state;
    if (this.notify && cable) {
      cable.subscriptions.remove(this.notify);
    }
  }

  render() {
    const { children } = this.props;
    const { checkInMessage } = this.state;

    return (
      <CheckInContext.Provider value={{ checkInMessage }}>
        {children}
      </CheckInContext.Provider>
    );
  }
}

export default ConnectActionCable;
