import cookie from 'js-cookie';

const handleAuthError = (error, updateUser, history) => {
  // Check for auth error message
  if (error?.message === 'GraphQL error: You are not authorized to access that resource') {
    // Clear auth cookie
    cookie.remove('token');
    
    // Reset user state 
    updateUser('reset');
    
    // Redirect to login page
    history.push('/login');
    
    return true;  // Error was handled
  }
  
  return false; // Error was not handled
};

export default handleAuthError;
