import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "js-cookie";
import Theme from "../../globalComponents/Theme.js";
import { compose, withApollo, Query } from "react-apollo";
import {
  OnboardingHeader,
  Button,
  Select,
} from "../../globalComponents/";
import { reduxForm, SubmissionError } from "redux-form";
import { updateUser } from "../../store/redux/actions";
import {  USER_UPDATE } from "../../store/mutations.js";
import { PARK_NAMES, USER_DETAILS } from "../../store/queries.js";
import { PulseLoader } from "react-spinners";
import Bg from "../../assets/fetch-BG.svg";
import { data } from "jquery";
import { filterHiddenParks } from '../../utils/parkUtils.js';

class ParkSelection extends Component {
  state = {
    park: "",
    initialize: true,
  };
  getId() {
    if (this.props.global.currentUser.selectedUser) {
      return this.props.global.currentUser.selectedUser;
    } else {
      return this.props.global.currentUser.id;
    }
  }
  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const height = obj.dimensions.height;
    const width = obj.dimensions.width;
    const {
      pristine,
      submitting,
      handleSubmit,
      error,
      history,
      touched,
      change,
    } = this.props;
    console.log("----------------------------------->", this.props.global);
    return (
      <Query
        fetchPolicy="no-cache"
        query={USER_DETAILS}
        variables={{ id: this.getId() }}
      >
        {({ loading, error, data, refetch }) => {
          console.log("Park", data);
          if (loading)
            return (
              <div
                name="container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: width,
                  minHeight: height,
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                  backgroundColor: Theme.BACKGROUND_COLOR,
                  backgroundRepeat: "round",
                  backgroundImage: `url(${Bg})`,
                }}
              >
                <PulseLoader
                  style={{ margin: "auto" }}
                  size={width / 10}
                  color={Theme.LINK_FONT_COLOR}
                  loading={loading}
                />
              </div>
            );
          // if (error) return `Error: ${error.message}`;
          if (error) {
            if (
              error?.message ===
              "GraphQL error: You are not authorized to access that resource"
            ) {
              cookie.remove("token");
              this.props.updateUser("reset");
              this.props.history.push("/login");
            }
            return `Error: ${error?.message}`;
            // sessionCheck(error, this.props.updateUser)
          }
          if (this.state.initialize && data.userDetail.park) {
            change("parkId", data.userDetail.park.id);

            this.setState({
              park: data.userDetail.park.name,
              initialize: false,
            });
          }
          return (
            <OnboardingHeader
              back={() =>
                this.props.global.currentUser.type === "Employee"
                  ? this.props.history.push("/dashboard")
                  : this.props.global.currentUser.type === "Manager" ||
                    this.props.global.currentUser.type === "Admin"
                  ? this.props.history.push("/manager/members")
                  : this.props.history.push("/")
              }
              progress={1}
              status = {true}
              handleSubmit={handleSubmit}
              containerStyle={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                alignContent: "center",
              }}
              checkInStatus={false}
            >
              <div
                style={{
                  marginBottom: "2%",
                  marginTop: "5%",
                  textAlign: "center",
                  fontFamily: Theme.HEADER_FONT,
                  fontSize: Theme.getHeaderFont(width),
                  color: Theme.HEADER_FONT_COLOR,
                }}
              >
               Home Park 
              </div>
              <Query fetchPolicy="no-cache" query={PARK_NAMES}>
                {({ loading, error, data, refetch }) => {
                  if (loading) return (
                    <div name="container" style={{
                      display: "flex",
                      flexDirection: "column",
                      width: width,
                      minHeight: height,
                      alignItems: "center",
                      alignContent: "center",
                      justifyContent: "center",
                      backgroundColor: Theme.BACKGROUND_COLOR,
                      backgroundRepeat: "round",
                      backgroundImage: `url(${Bg})`,
                    }}>
                      <PulseLoader
                        style={{ margin: "auto" }}
                        size={width / 10}
                        color={Theme.LINK_FONT_COLOR}
                        loading={loading}
                      />
                    </div>
                  );
                  if (error) return `Error: ${error.message}`;

                  // Filter out hidden parks
                  const filteredParks = filterHiddenParks(data?.restrictedFetchParks || []);

                  return (
                    <Select
                      alt={true}
                      options={filteredParks}
                      handleSelect={(park, name) => {
                        change(name, park.id);
                        this.setState({ park: park.name });
                      }}
                      selected={this.state.park}
                      name="parkId"
                      width={"100%"}
                      height={height / 25}
                      label="Park Location"
                    />
                  );
                }}
              </Query>

              {error && <strong style={{ color: "red" }}>{error}</strong>}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "5%",
                  width: Theme.getLongInputWidth(width) * 1.07,
                }}
              >
                {/* <Button
                                    text="Back"
                                    alt={true}
                                    width={width}
                                    onClick={() => history.push("/login")}
                                /> */}
                <Button
                  type="submit"
                  disabled={pristine || submitting}
                  containerStyle={{ marginLeft: "auto" }}
                  text="Save and Continue"
                  width={width}
                  onClick={handleSubmit}
                />
              </div>
            </OnboardingHeader>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

const formSubmit = (values, dispatch, props) => {
  var newValues = values;
  newValues.id =
    props.global.currentUser.selectedUser || props.global.currentUser.id;
  if (values.parkId > 0) {
    props.client
      .mutate({ mutation: USER_UPDATE, variables: { input: newValues } })
      .then(({ data: { updateUser } }) => {
        console.log("response", updateUser);
        if (updateUser.errors && updateUser.errors.length > 0) {
          console.error("Submission Error: ", updateUser.errors);
        } else
        console.log("Response", updateUser.user);
        props.updateUser({
            park: updateUser.user.park,
          });
        handleRouting(updateUser.user, props.history, updateUser);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
    return true;
  } else {
    throw new SubmissionError({
      _error: "Select a Park",
    });
  }
};
const handleRouting = (user, history, response) => {
  if (user.type === "Employee") {
    history.replace("/staff"); // Change For Park selection
  } else if (user.type === "Admin") {
    history.replace("/manager"); // Change For Park selection
  } else if (user.type === "Manager") {
    history.replace("/manager"); // Change For Park selection
  } else {
    cookie.remove("token");
    history.replace("/login");
  }
};
ParkSelection = reduxForm({
  form: "ParkSelection",
  onSubmit: formSubmit,
})(ParkSelection);

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(ParkSelection);
