import React, { Component } from "react";
import Theme from "../Theme.js";
import Arrow from "../../assets/arrow-down.svg";
import { filterHiddenParks } from "../../utils/parkUtils.js";

export default class ParkSelect extends Component {
  state = {
    open: false
  };

  componentWillMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  handleClick = e => {
    if (e.target) {
      try {
        if (this.node.contains(e.target)) {
          return;
        } else {
          this.setState({ open: false });
        }
      } catch (error) { }
    }
  };

  render() {
    const {
      label,
      selected,
      name,
      width,
      options,
      containerStyle = {},
      alt,
      icon,
      handleSelect,
      height
    } = this.props;
    
    // Filter out hidden parks
    const filteredOptions = filterHiddenParks(options);
    
    const { open } = this.state;
    return (
      <div
        ref={node => (this.node = node)}
        style={Object.assign({}, containerStyle, {
          position: "relative"
        })}
      >
        <div
          style={{
            fontSize: height / 2.2,
            fontFamily: Theme.HEADER_FONT,
            color: alt ? Theme.DARK_TEXT_COLOR : "white",
            marginBottom: "10px"
          }}
        >
          {label}
        </div>
        <button
          type="button"
          onClick={() => this.setState({ open: !open })}
          style={{
            outline: "none",
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            backgroundColor: "white",
            borderRadius: 5,
            border: alt ? "1px solid black" : "",
            width: width || "100%"
          }}
        >
          <div
            style={{
              fontSize: height / 2,
              fontFamily: Theme.HEADER_FONT,
              color: Theme.DARK_TEXT_COLOR,
              margin: "auto auto auto 0"
            }}
          >
            {selected}
          </div>
          <img
            alt=""
            style={{ margin: "auto 0 auto auto", height: height / 1.2 }}
            src={icon ? icon : Arrow}
          />
        </button>
        <div
          style={
            open
              ? {
                backgroundColor: "white",
                width: `100%`,
                display: "flex",
                flexDirection: "column",
                border: "1px solid",
                borderTop: "none",
                borderColor: alt ? "black" : Theme.MAIN_COLOR,
                marginTop: -4,
                maxHeight: height * 4,
                overflow: "auto",
                position: "absolute",
                fontSize: height / 2,
                fontFamily: Theme.INPUT_FONT,
                zIndex: 1000
              }
              : { display: "none" }
          }
        >
         
          {filteredOptions &&
            filteredOptions.map((option, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  handleSelect(option, name);
                  this.setState({ open: false });
                }}
                style={{
                  cursor: "pointer",
                  border: "none",
                  textAlign: "left",
                  fontSize: height / 2,
                  backgroundColor: "white",
                  outline: "none",
                  borderBottom: "1px solid #ccc",
                  padding: ".5em",
                  minHeight: 50
                }}
              >
                {option.name}
              </button>
            ))}
        </div>
      </div>
    );
  }
}
