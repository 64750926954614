import React, { Component } from "react";
import { connect } from "react-redux";
import { FETCH_PARKS } from "../../../store/queries.js";
import { Query } from "react-apollo";

import { Table, Theme, StaffNavBar } from "../../../globalComponents";
import { updateUser } from "../../../store/redux/actions";
import Bg from "../../../assets/fetch-BG.svg";
import { filterHiddenParks } from '../../../utils/parkUtils.js';
const testData = [
  {
    id: 6,
    park: "Atlanta",
    members: 200,
    manager: "Dude McDude",
    location: "Atlanta",
    phone: "404-444-4444",
  },
  {
    id: 7,
    park: "Nashville",
    members: 200,
    manager: "Dude McDude",
    location: "Atlanta",
    phone: "404-444-4444",
  },
];

class Parks extends Component {
  state = {
    tab: 0,
  };

  render() {
    const obj = this.props.global || { dimensions: { height: 0, width: 1000 } };
    const width = obj.dimensions.width;
    const shrink = width < 700;
    const columnNames = [
      "park",
      "members",
      "manager",
      "location",
      "phone",
      "action",
    ];
    const columns = {
      box: {
        label: "",
        renderCell: (data) => <input type="checkbox" />,
      },
      park: {
        label: "Park Name",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "11px" : Theme.getNormalFont(width),
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT,
              marginRight: "10%",
            }}
          >
            {data.name}
          </div>
        ),
      },
      members: {
        label: "Total Members",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "11px" : Theme.getNormalFont(width),
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.memberCount}
          </div>
        ),
      },
      manager: {
        label: "General Manager",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "11px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.generalManager}
          </div>
        ),
      },
      location: {
        label: "Location",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.DARK_TEXT_COLOR,
              fontWeight: 700,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "11px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.state}
          </div>
        ),
      },
      phone: {
        label: "Telephone#",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "11px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
            }}
          >
            {data.phoneNumber}
          </div>
        ),
      },
      action: {
        label: "Action",
        renderCell: () => (
          <button
            style={{
              fontWeight: 100,
              outline: "none",
              background: "transparent",
              border: "none",
              cursor: "pointer",
              color: Theme.MAIN_COLOR,
              // fontSize: Theme.getNormalFont(width),
              fontSize: shrink ? "11px" : Theme.getNormalFont(width),
              fontFamily: Theme.INPUT_FONT,
              paddingLeft: 0,
            }}
          >
            View
          </button>
        ),
      },
    };

    return (
    <Query fetchPolicy="no-cache" query={FETCH_PARKS}>
      {({ loading, error, data, refetch }) => {
        if (error) return `Error: ${error.message}`;
        if (loading) return null;
        if (!loading) {
          // Filter out the park we want to hide
          const filteredParks = data?.fetchParks ? 
            data.fetchParks.filter(park => park.name !== 'Columbus') :
            [];

          return (
            <div
              style={{
                height: this.props.global.dimensions.height,
                backgroundColor: Theme.BACKGROUND_COLOR,
                backgroundRepeat: "round",
                backgroundImage: `url(${Bg})`,
              }}
            >
              <StaffNavBar
                tab={1}
                history={this.props.history}
                width={this.props.global.dimensions.width}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexShrink: 0,
                  width: "90%",
                  justifyContent: "center",
                  marginTop: -80,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div
                  style={{
                    marginRight: "auto",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "-3%",
                    marginBottom: "4%",
                    width: "100%",
                    color: "white",
                    alignItems: "center",
                    alignContent: "center",
                    fontSize: shrink ? "11px" : Theme.getNormalFont(width),
                    fontFamily: Theme.HEADER_FONT,
                  }}
                >
                  Parks
                  <button
                    onClick={() => this.props.history.push("./parks/new")}
                    style={{
                      outline: "none",
                      backgroundColor: "rgba(250,250,250, .3)",
                      borderRadius: 5,
                      padding: "5px 10px 5px 10px",
                      border: "none",
                      marginLeft: "auto",
                      color: "white",
                      fontFamily: Theme.HEADER_FONT,
                      fontSize: shrink ? "11px" : Theme.getNormalFont(width),
                      cursor: "pointer",
                    }}
                  >
                    Add New Park
                  </button>
                </div>
                <Table
                  loading={loading}
                  containerStyle={{ flex: 1 }}
                  total={!loading && filteredParks.length}
                  names={columnNames}
                  alt={true}
                  columns={columns}
                  rows={!loading && filteredParks}
                  onClick={(id) =>
                    this.props.history.push("./parks/stats", { id })
                  }
                />
              </div>
            </div>
          );
        }
      }}
    </Query>
    );
  }
}
const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(Parks);
