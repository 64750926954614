import React, { Component, Fragment } from "react";
import {
  Button,
  SearchBar,
  Table,
  DogTable,
  ProfileButton,
  ParkLog,
  Select,
  PawIndicator,
} from "../../globalComponents";
import cookie from "js-cookie";
import { connect } from "react-redux";
import Bg from "../../assets/fetch-BG.svg";
import moment from "moment";
import { compose, withApollo, Query } from "react-apollo";
import Logo from "../../assets/fetch-logo.svg";
import Theme from "../../globalComponents/Theme.js";
import Active from "../../assets/paw-active.svg";
import Inactive from "../../assets/active_green.svg";
import CheckedNeutered from "../../assets/check_neutered.svg";
import Warning from "../../assets/paw_warning.svg";
import Vaccinated from "../../assets/active_green.svg";
import Expired from "../../assets/paw-expired.svg";
import DogPlaceholder from "../../assets/dog_placeholder.png";
import { updateUser, checkInDog } from "../../store/redux/actions.js";
import {
  USER_FEED,
  FETCH_PROFILE_PIC,
  PARK_NAMES,
  USER_NOTES,
  PARK_RULE_CHECK,
} from "../../store/queries";
import { BULK_CHECKIN, DOG_SPRAYED_UPDATE } from "../../store/mutations.js";
import { uniq as _uniq } from "lodash";
import SelectDashBoard from "../../globalComponents/FormComponents/SelectDashboard";
import { PulseLoader } from "react-spinners";
import { data } from "jquery";
import ConnectActionCable, {
  CheckInContext,
} from "../../globalComponents/Connection";


import handleAuthError from '../../utils/handleAuthError';


const styles = {
  web: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    width: "30%",
    // minWidth: 300,
    border: "1px solid",
    borderColor: Theme.LINK_FONT_COLOR,
    borderRadius: 10,
    marginTop: "0px",
    height: `${window.innerHeight > window.innerWidth ? 70 : 65}vh`,
  },
  mobile: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "5%",
    // width: "25%",
    minWidth: 300,
    border: "1px solid",
    borderColor: Theme.LINK_FONT_COLOR,
    borderRadius: 10,
  },
  webLogo: {
    marginTop: "-5%",
    height: 120,
    width: 150,
    marginLeft: "auto",
    marginRight: "auto",
  },
  mobileLogo: {
    marginTop: "-5%",
    height: 200,
    marginLeft: "auto",
    marginRight: "auto",
  },
};
const webLogo = {
  marginTop: "-5%",
  height: 120,
  width: 150,
  marginLeft: "auto",
  marginRight: "auto",
};
let demoLoad = false;
let ALL = "all";
let ACTIVE = "active";
let IN_ACTIVE = "in_active";
let NOT_CONFIRMED = "not_confirmed";
let PAUSED = "paused";
let BANNED = "banned";
class Dashboard extends Component {
  state = {
    page: 1,
    // per: Math.ceil(window.innerHeight / 100),
    per: 5,
    search: "",
    keyword: "",

    checkingIn: {},
    checkingInDogs: {},
    membersInPark: [],
    checkInButtonDisabled: false,
    park: "",
    parkId: "",
    selectedUserId: "",
    selectedNotes: [],
    spayedStatus: false,
    selectedSpayed: "",
    spayedDog: {},
    isLoading: false,
    tab: [],
    selectedFiltereds: {},
    selectedFilteredNames: [],
    isParkLoading: false,
    fetchParkLists: [],
    reRender: false,
  };

  componentDidMount() {
    this.setState(
      {
        park: this.props.global.currentUser.park.name,
        parkId: this.props.global.currentUser.park.id,
        // isParkLoading: true,
      },
      () => {
        this.getParkList();
      }
    );
  }

  isDisabled = () => {
    return this.state.checkInButtonDisabled;
  };

  checkPortrait = () => {
    return window.innerHeight > window.innerWidth;
  };

  displayMember = (data) => {
    const { rowType } = data;
    if (rowType) {
      // new logic for dogs
      let userId = null;
      if (rowType == "dog") {
        userId = data.userId;
      } else if (rowType == "user") {
        userId = data.id;
      } else {
        alert("rowType is not recognized");
      }

      if (userId) {
        this.props.updateUser({ selectedUser: userId });
        this.props.history.push({
          pathname: `/staff/member`,
          params: { id: userId },
        });
      } else {
        alert("Unable to get user id");
      }
    } else {
      // old logic for users
      this.props.updateUser({ selectedUser: data.id });
      this.props.history.push({
        pathname: `/staff/member`,
        params: { id: data.id },
      });
    }
  };

  selectBulkCheckbox = (_event, { id }) => {
    let { checkingIn } = this.state;
    if (id in checkingIn) {
      delete checkingIn[id];
    } else {
      checkingIn[id] = 1;
    }
    this.setState({ checkingIn });
  };
  checkParkRule = async (userId) => {
    let parkId = this.props.global?.currentUser?.park?.id;
    let variables = {
      userId,
      parkId,
    };
    let { selectedUserId } = this.state;
    if (userId !== selectedUserId) {
      let parkRuleStatus = await this.props.client
        .query({
          query: PARK_RULE_CHECK,
          variables: variables,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          return response.data?.fetchUserParkRuleStatus?.status;
        })
        .catch((error) => {
          return false;
        });
      return parkRuleStatus;
    } else {
      return true;
    }
  };
  selectBulkCheckboxForDogs = async (
    _event,
    { id, userId, parkId, notes, name, renewNeeded, acceptedRules }
  ) => {
    let status = true;
    var sId = "";
    sId = userId;
    let { checkingInDogs, selectedNotes, selectedUserId } = this.state;
    // let checkStatus = await this.checkParkRule(userId); Rollback in FP-665
    if (acceptedRules) {
      // ------ Selectection Start -----
      if (id in checkingInDogs) {
        delete checkingInDogs[id];
        this.setState({
          selectedUserId: userId,
        });
        this.props.checkInDog({ checkingInDogs });
      } else {
        if (renewNeeded) {
          if (
            window.confirm("Are you sure you want to renew this membership?")
          ) {
            checkingInDogs[id] = userId;
            this.props.checkInDog({ checkingInDogs });
            this.setState({
              selectedUserId: userId,
            });
          } else {
          }
        } else {
          checkingInDogs[id] = userId;
          this.props.checkInDog({ checkingInDogs });
          this.setState({
            selectedUserId: userId,
          });
        }
      }
      // ------ Selectection End -----
      this.setState({ checkingInDogs, parkId });
    } else {
      // alert("Please have the member accept the park rules before continuing.");
      checkingInDogs[id] = userId;
      this.props.checkInDog({ checkingInDogs });
      this.props.updateUser({ selectedUser: userId, checkingInDogs });
      alert("Member needs to sign a park specific waiver.");

      this.props.history.push("/members/parkrules");
    }
  };

  bulkDogsInputChange = (event, { id }) => {
    let { checkingIn } = this.state;
    checkingIn[id] = Number(event.target.value);
    if (checkingIn[id] === 0) {
      checkingIn[id] = "empty";
    }
    this.setState({ checkingIn });
  };

  displayCheckingInNum = () => {
    return Object.entries(this.state.checkingInDogs).length;
  };

  startCheckIn = () => {
    let { checkingIn, selectedUserId } = this.state;
    this.setState({ checkingIn });
    if (selectedUserId) {
      const checkedinArr = Object.entries(this.state.checkingInDogs);
      const allUsersIds = _uniq(checkedinArr.map((val) => val[1]));
      for (const userId of allUsersIds) {
        if (selectedUserId === userId) {
          this.checkedNoteCheckIn(userId);
          break;
        } else {
          this.checkedNoteCheckIn(userId);
          break;
        }
      }
    } else {
      alert("Need to select at least one dog.");
    }
  };

  checkedNoteCheckIn = (userId) => {
    let { client } = this.props;
    if (userId) {
      // Check IN start
      this.setState({
        checkInButtonDisabled: true,
      });
      const values = {
        id: Number(userId),
      };
      client
        .query({
          query: USER_NOTES,
          variables: values,
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          const {
            data: {
              userDetail: { notes, firstName, lastName },
            },
          } = response;

          if (notes.length > 0) {
            let note = notes && notes[0].content;
            if (
              window.confirm(`${firstName + " " + lastName} NOTE : ${note}`)
            ) {
              return new Promise((resolve, reject) => {
                this.handleBulkCheckIn();
              });
            } else {
              this.setState({
                checkInButtonDisabled: false,
              });
            }
          } else {
            if (
              window.confirm("Do you want to check-in the selected members?")
            ) {
              return new Promise((resolve, reject) => {
                this.handleBulkCheckIn();
              });
            } else {
              this.setState({
                checkInButtonDisabled: false,
              });
            }
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          return false;
        });
      // Check IN end
    }
  };

  handleBulkCheckIn = () => {
    if (this.displayCheckingInNum() > 0) {
      // new logic for dogs
      const checkingIn = [];
      const tempArr = [];

      const checkedinArr = Object.entries(this.state.checkingInDogs);
      const allUsersIds = _uniq(checkedinArr.map((val) => val[1]));

      const countDogsCheckedInForUser = (forUserId) => {
        let count = 0;
        let dogIdArr = [];

        for (let [dogId, userId] of checkedinArr) {
          if (forUserId == userId) dogIdArr.push(Number(dogId));
        }
        return dogIdArr;
      };
      for (const userId of allUsersIds) {
        const dogIds = countDogsCheckedInForUser(userId);
        checkingIn.push({ userId, dogCount: dogIds.length, dogIds });
      }
      // if (window.confirm("Do you want to check-in the selected members?")) {
      this.setState({
        checkInButtonDisabled: true,
      });
      this.props.client
        .mutate({
          mutation: BULK_CHECKIN,
          variables: {
            input: {
              viaApp: "web",
              users: checkingIn,
              parkId:
                this.props.global.currentUser.park &&
                this.props.global.currentUser.park.id,
              // ||
              // this.state.parkId,
            },
          },
        })
        .then((response) => {
          this.setState({
            checkInButtonDisabled: false,
          });
          if (response.data.checkIn.errors[0]) {
            alert(response.data.checkIn.errors[0].message);
          } else {
            alert("All selected member(s) successfully checked in!");
          }
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        })
        .catch((error) => {
          let error_msg = error.message.replace("GraphQL error:", "");
          alert(error_msg);
          this.setState({
            checkInButtonDisabled: false,
          });
        });
      // }
    } else {
      alert("Need to select at least one dog.");
    }
  };

  handleActiveStatus = (confirmed, subActive, subPaused, isBanned) => {
    let status = {
      text: "",
      icon: null,
    };
    if (isBanned) {
      status.text = "Banned";
      status.icon = Active;
    } else if (subPaused) {
      status.text = "Paused";
      status.icon = Active;
    } else if (confirmed && subActive) {
      status.text = "Active";
      status.icon = Active;
    } else if (!confirmed) {
      status.text = "Not Confirmed";
      status.icon = Inactive;
    } else if (!subActive) {
      status.text = "Inactive";
      status.icon = Inactive;
    }

    return status;
  };
  handleVaccineExpiredStatus = (confirmed, subActive, vaccineStatus) => {
    let status = {
      text: "",
      icon: null,
      alt: "",
    };
    if (vaccineStatus === "expiring_soon") {
      status.text = vaccineStatus;
      status.icon = Warning;
      status.alt = "warning";
    } else if (vaccineStatus === "expired") {
      status.text = vaccineStatus;
      status.icon = Expired;
      status.alt = "expired";
    } else if (vaccineStatus === "vaccinated") {
      status.text = vaccineStatus;
      status.icon = Vaccinated;
      status.alt = "vaccinated";
    }

    return status;
  };

  change = (name, _id) => {
    this.setState({
      parkId: _id,
    });
  };

  handleSPStatus = (id, isNeutered, refetch) => {
    let { client } = this.props;
    this.setState({
      isLoading: true,
    });
    client
      .mutate({
        mutation: DOG_SPRAYED_UPDATE,
        variables: {
          input: {
            id: id,
            isNeutered: !isNeutered,
          },
        },
        refetchQueries: () => [
          {
            query: USER_FEED,
            variables: {
              parkId: this.state.parkId,
              type: "Member",
              page: 1,
              per: 5,
              search: "",
            },
            fetchPolicy: "no-cache",
          },
        ],
      })
      .then((response) => {
        // refetch()
      })
      .catch((error) => {
        this.setState({ validation: error });
      });
  };
  handleSpayed = (spayedStatus, clickStatus) => {
    let status = false;
    if (spayedStatus) {
      status = true;
    } else if (!spayedStatus) {
      status = false;
    } else {
      status = false;
    }
    return status;
  };
  demoSpayed = (id, isNeutered, refetch) => {
    let { client } = this.props;
    this.setState({
      isLoading: true,
    });
    client
      .mutate({
        mutation: DOG_SPRAYED_UPDATE,
        variables: {
          input: {
            id: id,
            isNeutered: !isNeutered,
          },
        },
      })
      .then((response) => {
        // onUserUpdated();
        refetch();
      })
      .catch((error) => {
        this.setState({ validation: error });
      });
  };

  handleSearch = () => {
    let { keyword } = this.state;
    this.setState({
      search: keyword,
      page: 1,
    });
  };

  handleSelectFilter = (_event, id) => {
    let { selectedFiltereds } = this.state;
    if (id in selectedFiltereds) {
      delete selectedFiltereds[id];
    } else {
      selectedFiltereds[id] = id;
    }
    this.setState({ selectedFiltereds, page: 1 });
    let selectedFilteredNames = [];
    const checkedFiltered = Object.entries(selectedFiltereds);
    const membersIds = _uniq(checkedFiltered.map((val) => val[1]));
    for (const userId of membersIds) {
      selectedFilteredNames.push(userId);
    }

    this.setState({
      tab: selectedFilteredNames,
      selectedFilteredNames,
    });
  };

  getParkList = () => {
    this.setState({
      isParkLoading: true,
    });
    this.props.client
      .query({
        query: PARK_NAMES,

        fetchPolicy: "no-cache",
      })
      .then((response) => {
        this.setState({
          fetchParkLists: response.data?.restrictedFetchParks,
          isParkLoading: false,
        });
      })
      .catch((error) => {
        console.error("Error: ", error);
        return false;
      });
  };

  setCheckIN = (_data) => {
    this.setState({
      reRender: !this.state.reRender,
    });
  };
  render() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const {
      page,
      per,
      search,
      checkingInDogs,
      parkId,
      tab,
      selectedFiltereds,
      selectedFilteredNames,
      isParkLoading,
    } = this.state;
    const { isManager } = this.props;
    const shrink = width > 991;
    const verySmall = width < 568;
    const TillTab = width < 1024;
    const small = width < 1170;
    const columnNames = ["box", "status", "date", "name", "type"];

    const dogColumns = {
      box: {
        label: "Check-in ",
        renderUserName: (data) => {
          return (
            <td colSpan="5" onClick={() => this.displayMember(data)}>
              {`${data.firstName} ${data.lastName}`}
            </td>
          );
        },
        renderCell: (data) => {
          const {
            id: dogId,
            confirmed,
            vaccineStatus,
            pausedSubscription,
            isBanned,
            acceptedRules,
          } = data;
          return !isBanned &&
            ((confirmed && vaccineStatus !== "expired") ||
              pausedSubscription) ? (
            <div
              style={{
                display: "flex",
                //  width: '210px',
                width: "100%",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  width: "15%",
                  flexDirection: "column",
                }}
              >
                <input
                  style={{
                    width: "20px",
                    // width:"100%",
                    height: "20px",
                  }}
                  type="checkbox"
                  checked={checkingInDogs[dogId] || false}
                  onChange={(e) => this.selectBulkCheckboxForDogs(e, data)}
                />
              </div>
              <div
                style={{
                  display: "inline-flex",
                  // width: "165px",
                  width: "60%",
                  flexDirection: "column",
                  padding: "0px 2px",
                }}
              >
                <span
                  style={{
                    lineHeight: TillTab ? "14px" : "26px",
                    marginLeft: "10px",
                    fontSize: TillTab ? "12px" : "14px",
                  }}
                >
                  {data.name}
                </span>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  // width: "45px",
                  width: "25%",
                  flexDirection: "column",
                  overflow: "hidden",
                  borderRadius: "5px",
                  height: "100%",
                  marginLeft: "3px",
                }}
              >
                <img
                  key={data.id}
                  alt={data.profilePicture.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={
                    // process.env.REACT_APP_API_ENDPOINT + data.profilePicture.url
                    data.profilePictureCdn
                  }
                  onError={(event) =>
                    event.target.setAttribute("src", DogPlaceholder)
                  }
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "inline-flex",
                  width: "15%",
                  flexDirection: "column",
                }}
              >
                <input
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                  type="checkbox"
                  checked={false}
                  disabled
                />
              </div>{" "}
              <div
                style={{
                  display: "inline-flex",
                  width: "60%",
                  flexDirection: "column",
                  padding: "0px 2px",
                }}
              >
                <span
                  style={{
                    lineHeight: TillTab ? "14px" : "26px",
                    marginLeft: "10px",
                    fontSize: TillTab ? "12px" : "14px",
                  }}
                >
                  {data.name}
                </span>
              </div>
              <div
                style={{
                  display: "inline-flex",
                  // width: "45px",
                  width: "25%",
                  flexDirection: "column",
                  overflow: "hidden",
                  borderRadius: "5px",
                  height: "100%",
                  marginLeft: "3px",
                }}
              >
                <img
                  key={data.id}
                  alt={data.profilePicture.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  src={
                    // process.env.REACT_APP_API_ENDPOINT + data.profilePicture.url
                    data.profilePictureCdn
                  }
                  onError={(event) =>
                    event.target.setAttribute("src", DogPlaceholder)
                  }
                />
              </div>
            </div>
          );
        },
      },
      status: {
        label: "Status",
        onClickFunction: (data) => this.displayMember(data),
        renderCell: (data) => (
          <div
            style={{
              display: TillTab ? "block" : "flex",
              cursor: "pointer",
              flexDirection: `${width > 1000 ? "row" : "column"}`,
            }}
            onClick={() => this.displayMember(data)}
          >
            <div
              style={{
                color: Theme.LIGHT_TEXT_COLOR,
                fontSize: `${width > 1000 ? "12" : "12"}px`,
                fontWeight: 100,
                fontFamily: Theme.INPUT_FONT,
                marginLeft: `${width > 1000 ? "10%" : "auto"}`,
                textAlign: "left",
                marginTop: "auto",
                marginBottom: "auto",
                display: TillTab ? "inline-block" : " ",
                float: TillTab ? "left" : " ",
              }}
            >
              {
                this.handleActiveStatus(
                  data.confirmed,
                  data.subscriptionActive,
                  data.pausedSubscription,
                  data.isBanned
                ).text
              }
            </div>
            {data.confirmed && (
              <img
                alt={
                  this.handleVaccineExpiredStatus(
                    data.confirmed,
                    data.subscriptionActive,
                    data.vaccineStatus
                  ).alt
                }
                style={
                  data.vaccineStatus === "expired"
                    ? {
                        marginRight: TillTab ? "0px" : "10%",
                        marginLeft: TillTab ? "10px" : "auto",
                        marginTop: `${width > 1000 ? "0" : "0"}px`,
                        float: TillTab ? "right" : " ",
                        background: "#404040",
                        padding: " 5px",
                        borderRadius: "6px",
                      }
                    : {
                        marginRight: TillTab ? "0px" : "10%",
                        marginLeft: TillTab ? "10px" : "auto",
                        marginTop: `${width > 1000 ? "0" : "0"}px`,
                        float: TillTab ? "right" : " ",
                        padding: " 5px",
                        borderRadius: "6px",
                      }
                }
                src={
                  this.handleVaccineExpiredStatus(
                    data.confirmed,
                    data.subscriptionActive,
                    data.vaccineStatus
                  ).icon
                }
              />
            )}
          </div>
        ),
      },
      date: {
        label: "Date",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontWeight: 100,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
            }}
            onClick={() => this.displayMember(data)}
          >
            {moment(data.signUpDate, "YYYY-MM-DD").format("L")}
          </div>
        ),
      },
      name: {
        label: "S/P",
        renderCell: (data, refetch) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
              width: 25,
              height: 25,
              position: "absolute",
              top: "0px",
              bottom: "0px",
              margin: "auto",
            }}
          >
            <button
              onClick={(e) =>
                this.handleSPStatus(data.id, data.isNeutered, refetch)
              }
              type="button"
              style={{
                width: 25,
                height: 25,
                border: "2px solid",
                cursor: "pointer",
                borderColor: "#49c172",
                outline: "none",
                borderRadius: 5,
                padding: "0px",
                background: this.handleSpayed(data.isNeutered)
                  ? "#49c172"
                  : "transparent",
              }}
            >
              {this.handleSpayed(data.isNeutered) ? (
                <img
                  active={false}
                  alt="Active"
                  src={CheckedNeutered}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              ) : null}
            </button>
          </div>
        ),
      },
      type: {
        label: "Member Type",
        renderCell: (data) => (
          <div
            style={{
              color: Theme.LIGHT_TEXT_COLOR,
              fontWeight: 100,
              fontSize: `${width > 1000 ? "14" : "12"}px`,
              fontFamily: Theme.INPUT_FONT,
              textAlign: "left",
              cursor: "pointer",
            }}
            onClick={() => this.displayMember(data)}
          >
            {/* {data.packageName} */}
            {`${data.packageName ? data.packageName : "No Package "} ${
              data.renewNeeded ? "(RENEW)" : ""
            }`}
          </div>
        ),
      },
    };
    const tabStyles = {
      navButton: {
        outline: "none",
        backgroundColor: "#f17d50",
        border: "none",
        padding: 10,
        margin: "auto 2% auto 2%",
        color: "white",
        fontFamily: Theme.INPUT_FONT,
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
        borderRadius: "5px",
      },
      // navButton: {
      //   outline: "none",
      //   backgroundColor: "white",
      //   border: "1px solid #606267",
      //   padding: 10,
      //   margin: "auto 2% auto 2%",
      //   color: "#606267",
      //   fontFamily: Theme.INPUT_FONT,
      //   // fontSize: Theme.getNormalFont(this.props.global.dimensions.width),
      //   fontSize: shrink ? "12px" : Theme.getSmallFont(this.props.global.dimensions.width),
      //   cursor: "pointer",
      //   borderRadius: "5px",
      //   boxShadow:"#606267 3px 3px 10px"
      // },
      selectedButton: {
        outline: "none",
        backgroundColor: "white",
        borderRadius: 5,
        padding: 10,
        border: "2px solid #ff7d50",
        color: Theme.MAIN_COLOR,
        margin: "auto 2% auto 2%",
        fontFamily: Theme.HEADER_FONT,
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
        borderRadius: "5px",
        boxShadow: "#606267 3px 3px 10px",
      },
      deactivate: {
        outline: "none",
        backgroundColor: "rgba(250,250,250, .3)",
        borderRadius: 5,
        padding: 5,
        border: "none",
        color: "white",
        margin: "auto",
        marginRight: 10,
        fontFamily: Theme.HEADER_FONT,
        fontSize: shrink
          ? "12px"
          : Theme.getSmallFont(this.props.global.dimensions.width),
        cursor: "pointer",
        borderRadius: "5px",
      },
    };
    let lengthSelected = Object.keys(selectedFiltereds).length;
    if (isParkLoading) return null;
    return (
      <ConnectActionCable
        parkId={this.state.parkId}
        status={true}
        setCheckIN={() => {
          this.setCheckIN();
        }}
      >
        <div
          name="container"
          style={{
            display: "flex",
            flexDirection: "column",
            width: width,
            minHeight: height,
            alignItems: "center",
            backgroundColor: Theme.BACKGROUND_COLOR,
            paddingBottom: "10%",
            backgroundRepeat: "round",
            backgroundImage: `url(${Bg})`,
          }}
        >
          <div
            name="headerContainer"
            style={{
              display: "flex",
              flexShrink: 0,
              flexDirection: "column",
              width: small ? "90%" : "80%",
              paddingTop: "2%",
              paddingLeft: shrink ? "0%" : "5%",
              paddingRight: shrink ? "0%" : "5%",
            }}
          >
            <div style={{ display: "flex" }}>
              <Button
                text="Add New Member"
                fullwidth={false}
                onClick={() => {
                  this.props.updateUser({ selectedUser: true });
                  this.props.history.push("/members/onboarding/");
                }}
                width={width}
              />
              {!isManager && (
                <Query
                  fetchPolicy="cache-and-network"
                  query={FETCH_PROFILE_PIC}
                  variables={{
                    id: this.props.global.currentUser.id,
                  }}
                >
                  {({ loading, error, data, refetch }) => {
                    if (error) {
                      if (
                        error?.message ===
                        "GraphQL error: You are not authorized to access that resource"
                      ) {
                        cookie.remove("token");
                        this.props.updateUser("reset");
                        this.props.history.push("/login");
                      }
                      return `Error: ${error?.message}`;
                    }
                    return (
                      <ProfileButton
                        history={this.props.history}
                        picture={
                          data &&
                          data.userDetail &&
                          // process.env.REACT_APP_API_ENDPOINT +
                          //   data.userDetail.profilePicture.url
                          data.userDetail.profilePictureCdn
                        }
                        width={width}
                        containerStyle={{ marginLeft: "auto" }}
                      />
                    );
                  }}
                </Query>
              )}
            </div>
            {!isManager && (
              <img
                alt="logo"
                src={Logo}
                style={{
                  ...webLogo,
                  display: verySmall ? "none" : "",
                }}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "90%",
              marginTop: verySmall ? "35px" : "0px",
              flexWrap: "wrap",
              marginLeft: small ? "auto" : 0,
              marginRight: small ? "auto" : 0,
              justifyContent: small ? "center" : "",
            }}
          >
            <div style={{ display: "flex", marginTop: "auto", width: "100%" }}>
              <div style={{ marginLeft: "auto", marginBottom: "2%" }}>
                <Button
                  text="Check In"
                  fullwidth={false}
                  onClick={() => this.props.history.push("/checkin")}
                  width={width}
                  href="/staff"
                />
              </div>
            </div>
            <div
              style={{
                flexDirection: "column",
                width: shrink ? "67%" : "100%",
                marginBottom: shrink ? "0" : "20px",
                // width: "67%"
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: verySmall ? "block" : "flex",
                  width: "100%",
                }}
              >
                <SearchBar
                  value={search}
                  containerStyle={{
                    marginBottom: "2%",
                    marginRight: "1%",
                    position: "relative",
                    // width: shrink ? "400px" : "300px",
                  }}
                  placeholder="Search"
                  // onChange={v => this.setState({ keyword: v })}
                  onChange={(v) => {
                    this.setState({ search: v, page: 1 });
                    lengthSelected <= 0
                      ? this.handleSelectFilter(v, ALL)
                      : this.setState({ tab: selectedFilteredNames });
                  }}
                  width={verySmall ? "94.5%" : width / 3}
                  height={verySmall ? "48px" : height / 20}
                />
                {/* <Button
                text={`Search`}
                fullwidth={false}
                // onClick={() => this.handleSearch()}
                disabled={(keyword === "")}
                width="100%"
                height={height / 20}
              // href="/staff"
              /> */}

                {/* <Query fetchPolicy="no-cache" query={PARK_NAMES}>
                  {({ loading, error, data, refetch }) => {
                    if (error) return `Error: ${error.message}`;
                    return ( */}
                <SelectDashBoard
                  alt={true}
                  // options={data.restrictedFetchParks}
                  options={this.state.fetchParkLists}
                  handleSelect={(park, name) => {
                    this.change(name, park.id); // Change CHeck for All PArk
                    this.setState({ park: park.name }); // Change CHeck for All PArk
                  }}
                  selected={this.state.park}
                  name="parkId"
                  width={"100%"}
                  height={height / 25}
                  label="Park Location"
                />
                {/* );
                  }}
                </Query> */}

                <div
                  style={{
                    //  marginLeft: "auto" ,
                    margin: `${
                      width < 900
                        ? width < 568
                          ? "15px auto 10px"
                          : "0px auto 10px"
                        : "0 0 0 auto "
                    }`,
                    display: `${width < 900 ? "inline-flex" : "flex "}`,
                    flexDirection: `${width < 900 ? "column" : "row"}`,
                  }}
                >
                  <Button
                    text={`Checking In: ${this.displayCheckingInNum()}`}
                    fullwidth={false}
                    onClick={() => this.startCheckIn()}
                    disabled={this.isDisabled()}
                    width="100%"
                    height={height / 20}
                    href="/staff"
                    loadingText="Wait"
                    loading={this.state.checkInButtonDisabled}
                  />
                </div>
              </div>
              {
                // (search !== "") &&
                <div
                  style={{
                    marginRight: "auto",
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "1%",
                    marginBottom: "2%",
                    width: "100%",
                    color: "white",
                    alignItems: "center",
                    alignContent: "center",
                    fontSize: shrink ? "12px" : Theme.getHeaderFont(width),
                    fontFamily: Theme.HEADER_FONT,
                  }}
                >
                  <h2
                    style={{
                      fontSize: "15px",
                      fontFamily: Theme.HEADER_FONT,
                      color: "#000",
                      textAlign: "center",
                    }}
                  >
                    Filter :
                  </h2>
                  <button
                    onClick={(e) => this.handleSelectFilter(e, ALL)}
                    style={
                      selectedFiltereds[ALL]
                        ? tabStyles.selectedButton
                        : tabStyles.navButton
                    }
                  >
                    All
                  </button>
                  <button
                    onClick={(e) => this.handleSelectFilter(e, ACTIVE)}
                    // onClick={() =>
                    //   this.setState({ tab: ACTIVE })
                    // }
                    style={
                      selectedFiltereds[ACTIVE]
                        ? tabStyles.selectedButton
                        : tabStyles.navButton
                    }
                  >
                    Active
                  </button>

                  <button
                    onClick={(e) => this.handleSelectFilter(e, IN_ACTIVE)}
                    // onClick={() =>
                    //   this.setState({ tab: IN_ACTIVE })
                    // }
                    style={
                      selectedFiltereds[IN_ACTIVE]
                        ? tabStyles.selectedButton
                        : tabStyles.navButton
                    }
                  >
                    Inactive
                  </button>

                  <button
                    onClick={(e) => this.handleSelectFilter(e, NOT_CONFIRMED)}
                    // onClick={() =>
                    //   this.setState({ tab: NOT_CONFIRMED })
                    // }
                    style={
                      selectedFiltereds[NOT_CONFIRMED]
                        ? tabStyles.selectedButton
                        : tabStyles.navButton
                    }
                  >
                    Not Confirmed
                  </button>
                  <button
                    onClick={(e) => this.handleSelectFilter(e, PAUSED)}
                    // onClick={() =>
                    //   this.setState({ tab: PAUSED })
                    // }
                    style={
                      selectedFiltereds[PAUSED]
                        ? tabStyles.selectedButton
                        : tabStyles.navButton
                    }
                  >
                    Paused
                  </button>
                  <button
                    onClick={(e) => this.handleSelectFilter(e, BANNED)}
                    style={
                      selectedFiltereds[BANNED]
                        ? tabStyles.selectedButton
                        : tabStyles.navButton
                    }
                  >
                    Banned
                  </button>
                </div>
              }
              {(selectedFilteredNames.length > 0 || search !== "") && (
                <Query
                  fetchPolicy="no-cache"
                  query={USER_FEED}
                  variables={{
                    parkId: parkId,
                    type: "Member",
                    page,
                    per,
                    search,
                    keywords: tab,
                  }}
                >
                  {({ loading, error, data, refetch }) => {
                    if (loading)
                      return (
                        <div
                          name="container"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            // width: width,
                            minHeight: height,
                            alignItems: "center",
                            alignContent: "center",
                            justifyContent: "center",
                            backgroundColor: Theme.BACKGROUND_COLOR,
                            backgroundRepeat: "round",
                            backgroundImage: `url(${Bg})`,
                          }}
                        >
                          <PulseLoader
                            style={{ margin: "auto" }}
                            size={width / 10}
                            color={Theme.LINK_FONT_COLOR}
                            loading={loading}
                          />
                        </div>
                      );

                    if (error) {
                       if (handleAuthError(error, this.props.updateUser, this.props.history)) {
                        return null;
                        }
                        return `Error: ${error.message}`;
                    }

                    return (
                      <>
                        {/* <Table
                      loading={loading}
                      page={page}
                      per={per}
                      containerStyle={{ flex: 1, marginLeft: small ? 0 : "5%" }}
                      names={columnNames}
                      columns={columns}
                      onPage={page => this.setState({ page: page })}
                      onPer={per => this.setState({ per: per })}
                      rows={data.fetchUsers && data.fetchUsers.edges}
                      total={data.fetchUsers && data.fetchUsers.totalCount}
                      onClick={() => { }}
                    /> */}

                        <DogTable
                          loading={loading}
                          page={page}
                          per={per}
                          containerStyle={{
                            flex: 1,
                            marginLeft: small ? 0 : "5%",
                          }}
                          names={columnNames}
                          columns={dogColumns}
                          onPage={(page) => this.setState({ page: page })}
                          onPer={(per) => this.setState({ per: per })}
                          rows={data?.fetchUsers && data.fetchUsers.edges}
                          total={data?.fetchUsers && data.fetchUsers.totalCount}
                          onClick={() => {}}
                          width={width}
                          refetch={refetch}
                        />
                      </>
                    );
                  }}
                </Query>
              )}
            </div>
            {!this.state.isParkLoading && (
              <ParkLog
                parkId={parkId}
                client={this.props.client}
                history={this.props.history}
                reRender={this.state.reRender}
              />
            )}
          </div>
        </div>
      </ConnectActionCable>
    );
  }
}

const mapStateToProps = (state) => ({ ...state.global });
const mapDispatchToProps = { updateUser, checkInDog };

export default compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(Dashboard);
